import React from "react";
import { createUseStyles } from "react-jss";
import { AnimatePresence } from "framer-motion";

import Onboarding from "./screens/onboarding";

import Places from "./screens/places";
import Header from "./components/header";

function App() {
  const [loading, setLoading] = React.useState(true);
  const [location, setLocation] = React.useState(null); //null = not asked for, false = unsupported, true should not be used.

  React.useEffect(() => {
    //check if the user's browser supports location api
    if (!navigator.geolocation) {
      setLocation(false);
    }

    setLoading(false);
  }, []); //only run on load.

  function getLocation() {
    navigator.geolocation.getCurrentPosition((locationData) =>
      setLocation(locationData)
    );
  }

  const classes = useStyles();
  if (loading) {
    return (
      <div className={classes.container}>
        <h1>Loading...</h1>
        <p>We know you're hungry, we're working as fast as we can!</p>
      </div>
    );
  } else {
    if (location === false) {
      //excuse if browser doesn't support it lol, make the user feel better
      return (
        <div className={classes.container}>
          <h1>Oh No!</h1>
          <h3>Your browser doesn't support getting location.</h3>
          <p>Please try using another browser, or restart your device.</p>
        </div>
      );
    } else {
      //user's all good! using a modern browser.
      return (
        <div className={classes.container}>
          <AnimatePresence>
            <Header
              key="header"
            />
            <div className={classes.content}>
              {location !== null ? (
                <Places key="places" location={location} />
              ) : (
                <Onboarding key="onboarding" getLocation={() => getLocation()} />
              )}
              <a className={classes.socialLink} href="https://twitter.com/jayatflyt" target="_blank" rel="noreferrer">
                Made with love - @JayAtFlyt
              </a>
            </div>
          </AnimatePresence>
        </div>
      );
    }
  }
}

const useStyles = createUseStyles({
  container: {
    width: "100vw",
    height: "100vh",
    color: "#f1f1f1",
    backgroundColor: "#111",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "'Roboto', sans-serif", //catch all
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  content: {
    height: "calc(100% - 120px)",
    width: "95%",
    maxHeight: 900,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  socialLink: {
    color: "#FFF",
    opacity: 0.3,
    alignSelf: "center",
    textAlign: "center",
    margin: "auto",
  },
});

export default App;
