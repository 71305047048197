import React from "react";
import { createUseStyles } from "react-jss";

function Header(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h3 className={classes.wordMark} onClick={() => window.location.reload()}>
        FeedUs
      </h3>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    width: "90%",
    margin: [20, 0],
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  wordMark: {
    fontFamily: "'Barlow Semi Condensed', sans-serif",
    fontSize: 44,
    fontWeight: 800,
    margin: 0,
    cursor: "pointer",
  }
});

export default Header;
