import React from "react";
import { createUseStyles } from "react-jss";
import { motion, useMotionValue, animate } from "framer-motion";

import { firestore, auth, fieldValue } from "../helpers/firebase";

import IconButton from "./elements/iconButton";
import Button from "./elements/button";

function Card(props) {
  //framer motion
  const cardElem = React.useRef(null);

  const x = useMotionValue(0);

  function panHandle() {
    if (Math.abs(x.get()) > window.innerWidth / 4 || Math.abs(x.get()) > 300) {
      props.onSwipe();
    } else {
      x.stop();
      animate(x, 0);
    }
  }

  const classes = useStyles();
  return (
    <motion.div
      animate={
        props.active
          ? { y: 20, scale: 1, opacity: 1 }
          : { y: -15, scale: 0.95, opacity: 1 }
      }
      drag={props.active ? "x" : false}
      draggable={false}
      ref={cardElem}
      dragElastic={1}
      style={{ x, zIndex: props.active ? 5 : 3 }}
      whileTap={{ scale: props.active ? 1.05 : null }}
      initial={{ y: -100, scale: 0.8, opacity: 0 }}
      transition={{ delay: props.active ? 0 : 0.1 }}
      exit={{ opacity: 0, scale: 0.8, rotate: 60 }}
      className={classes.container}
      onPanEnd={() => {
        panHandle();
      }}
    >
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${props.place.photos[0].getUrl({
            maxWidth: 700,
            maxHeight: 700,
          })})`,
        }}
        alt={`${props.place.name}`}
      />

      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div>
            <h1>{props.place.name}</h1>
            <p>
              {props.place.rating} our of 5 Stars, {props.place.user_ratings_total} total ratings.
            </p>
          </div>
          {auth.currentUser ? (
            <IconButton
              icon={props.isFavorite ? "star" : "star_border"}
              fontSize={30}
              onClick={() => {
                if (props.isFavorite) {
                  //button is a toggle.
                  props.toggleFavorite(false);
                } else {
                  props.toggleFavorite(true);
                }
              }}
            />
          ) : null}
        </div>
        <Button
          onClick={() => {
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${props.place.geometry.location.lat()}%2C${props.place.geometry.location.lng()}&query_place_id=${
                props.place.place_id
              }`
            );
          }}
        >
          Google Maps
        </Button>
      </div>
    </motion.div>
  );
}

const useStyles = createUseStyles({
  container: {
    position: "absolute",
    width: "80%",
    minWidth: 257,
    maxWidth: 530,
    height: "85%",
    maxHeight: 800,
    display: "flex",
    flexDirection: "column",
    background: "#282828",
    color: "#FFF",
    borderRadius: 8,
    userSelect: "none",
    "user-drag": "none",
    overflowY: "scroll",
    boxShadow: "0px 12px 38px 5px rgba(0,0,0,0.35)",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  image: {
    width: "100%",
    flex: 1,
    display: "block",
    userSelect: "none",
    "user-drag": "none",
    borderRadius: [8, 8, 0, 0],
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "space-between",
    margin: [0, 12, 15, 12],
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default Card;
