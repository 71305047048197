import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCT01SnaIAShXFOLje5Ad7X8zEjrZO48e8",
  authDomain: "feedme-321521.firebaseapp.com",
  projectId: "feedme-321521",
  storageBucket: "feedme-321521.appspot.com",
  messagingSenderId: "659408336391",
  appId: "1:659408336391:web:3d969de5b3b58c32fd1ca2",
  measurementId: "G-R4ZVC52CQZ",
};

export default firebase.initializeApp(config);

firebase.analytics();

export const auth = firebase.auth();
export var provider = new firebase.auth.GoogleAuthProvider();

export const firestore = firebase.firestore();

//love js exports
export const fieldValue = firebase.firestore.FieldValue;
