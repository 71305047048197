import React from "react";
import { createUseStyles } from "react-jss";

import Button from "./elements/button";

function SearchOptions(props) {
  const [currentOnboarding, setCurrentOnboarding] = React.useState(0);
  const [searchOptions, setSearchOptions] = React.useState({});

  function setOption(key, value) {
    const currentOptions = searchOptions;
    currentOptions[key] = value;
    setSearchOptions(currentOptions);

    setCurrentOnboarding(currentOnboarding + 1);

    //if there are no more options, pass the state to parent.
    if (optionComponents.length - 1 === currentOnboarding) {
      props.setOptions(searchOptions);
    }
  }

  const optionComponents = [
    <Option2 setOption={(key, value) => setOption(key, value)} />,
  ];

  return <div style={{width: "100%", height: "100%"}}>{optionComponents[currentOnboarding]}</div>;
}

function Option1(props) {
  //leaving in just in case the api gets good/i get less lazy
  const [distance, setDistance] = React.useState(10);
  return (
    <div>
      <h3>How far are you willing to go?</h3>
      <h2>{distance} Miles</h2>
      <input
        type="range"
        min={5}
        max={50}
        value={distance}
        onChange={(v) => setDistance(v.target.value)}
      />
      <button onClick={() => props.setOption("distance", distance)}>
        Apply
      </button>
    </div>
  );
}

function Option2(props) {
  const [priceValue, setPriceValue] = React.useState(2);

  const classes = option2UseStyles();
  return (
    <div className={classes.container}>
      <h3 className={classes.header}>How much are you willing to spend?</h3>
      <div className={classes.dollarContainer}>
        <h2 className={classes.dollarText}>
          {Array.apply(null, { length: priceValue }).map(() => "$")}
        </h2>
        <input
          className={classes.slider}
          type="range"
          min={1}
          max={4}
          value={priceValue}
          onChange={(v) => setPriceValue(v.target.value)}
        />
      </div>
      <Button onClick={() => props.setOption("price", priceValue)}>
        Search
      </Button>
    </div>
  );
}

const option2UseStyles = createUseStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  header: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: 34,
  },
  dollarContainer: {
    display: "flex",
    flexDirection: "column"
  },
  dollarText: {
    fontFamily: "'Barlow Semi Condensed', sans-serif",
    fontWeight: 800,
    fontSize: 104,
    margin: [30, 0],
  },
  slider: {
    "-webkit-appearance": "none",
    width: "100%",
    background: "RGBA(248, 255, 101, .2)",
    outline: "none",
    borderRadius: 20,
    height: 7,
    margin: [20, 0],
    "&::-webkit-slider-thumb": {
      appearance: "none",
      width: 23,
      height: 23,
      cursor: "pointer",
      background: "#F8FF65",
      borderRadius: "50%",
    },
  },
});

export default SearchOptions;
