import React from "react";
import { motion } from "framer-motion";
import Button from "./elements/button";
import { createUseStyles } from "react-jss";

import ExampleCard from "../content/ExampleCard.svg";

function IntroCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <motion.div
        className={classes.animationContainer}
        initial={{ scale: 0.8 }}
        animate={{
          opacity: 1,
          scale: 0.8,
          x: [0, 40, -40, 0],
          rotateZ: [0, 10, -10, 0],
        }}
        transition={{
          delay: 0.5,
          duration: 1.5,
          repeat: Infinity,
          repeatDelay: 1,
        }}
      >
        <img src={ExampleCard} alt="Card" className={classes.exampleCard} />
      </motion.div>
      <div className={classes.infoContainer}>
        <h1>How to:</h1>
        <p>
          Swipe to dismiss, hit Google Maps when you find something you like!
        </p>
        <Button onClick={() => props.completed()}>Got it!</Button>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    width: "80%",
    maxWidth: 400,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    scrollbarWidth: 0,
    marginBottom: 20, //account for new safari being the stupidest thing
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  animationContainer: {
    flex: 1,
    overflowX: "hidden",
    display: "flex",
  },
  exampleCard: {
    backgroundColor: "#282828",
    borderRadius: 8,
    display: "block",
    height: "100%",
    width: "100%",
    maxWidth: 320,
    objectFit: "contain",
    margin: "auto",
  },
  infoContainer: {
    flex: 1,
  },
});

export default IntroCard;
