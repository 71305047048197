import React from "react";
import { createUseStyles } from "react-jss";

function Button(props) {
  const classes = useStyles();
  return (
    <button className={classes.button} {...props}>
      {props.children}
    </button>
  );
}

const useStyles = createUseStyles({
  button: {
    backgroundColor: "#F8FF65",
    color: "#111111",
    padding: 15,
    width: "100%",
    border: "none",
    borderRadius: 8,
    fontSize: 20,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
  },
});

export default Button;
