import React from "react";
import { createUseStyles } from "react-jss";

import { Loader } from "@googlemaps/js-api-loader";

//TODO: add a loader to chrome's location. it's so slow.

//regret.
import { firestore, auth, fieldValue } from "../helpers/firebase";

import SearchOptions from "../components/searchOptions";
import Card from "../components/card";
import Button from "../components/elements/button";
import IntroCard from "../components/introCard";

function Places(props) {
  const [loading, setLoading] = React.useState(true);
  //search options for the request. retrieved from the searchOptions component
  const [searchOptions, setSearchOptions] = React.useState(false);
  //have we already requested results? (so we know if we should request results when the array runs out.)
  const [firstResults, setFirstResults] = React.useState(false);
  //results from google. empty util populated.
  const [searchResults, setSearchResults] = React.useState([]);
  //track our current result and increment when needed.
  //when we need more results, increment this and make a new request.
  const [nextPage, setNextPage] = React.useState();
  const [isNewUser, setNewUser] = React.useState(true); //assume true, onboarding is not required, but nice.
  const [favorites, setFavorites] = React.useState({});

  //magic number for google maps. don't touch.
  const resultsContainer = React.useRef(null);

  React.useEffect(() => {
    if (localStorage.getItem("newUser") === "false") {
      //lord i cannot stand strings.
      setNewUser(false); //state is already true, don't do anything if it's not explicitly false.
    }

    if (!firstResults) {
      if (auth.currentUser) {
        firestore
          .collection("users")
          .doc(auth.currentUser.uid)
          .onSnapshot((snapshot) => {
            setFavorites(snapshot.data()["favorites"]);
          });
      }
      if (searchOptions) {
        queryMaps();
        setFirstResults(true);
      }
    } else {
      if (loading) {
        setLoading(false);
      }

      //query maps when we get low on results
      if (searchResults.length === 5 && nextPage.hasNextPage) {
        nextPage.nextPage();
      }
    }
  }, [searchOptions, searchResults, isNewUser, auth.currentUser]);

  function queryMaps() {
    const mapsLoader = new Loader({
      apiKey: "AIzaSyAI7IRCuhTLejzOgPMxg3cKjhsPcI3HYnA",
      version: "weekly",
      libraries: ["places"],
    });

    mapsLoader.load().then((google) => {
      var currentLocation = new google.maps.LatLng(
        props.location.coords.latitude,
        props.location.coords.longitude
      );

      var request = {
        location: currentLocation,
        rankBy: google.maps.places.RankBy.DISTANCE,
        type: ["restaurant"],
        maxPriceLevel: searchOptions.price,
        openNow: true,
      };

      var service = new google.maps.places.PlacesService(
        resultsContainer.current
      );

      service.nearbySearch(request, (result, status, pagination) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setNextPage(pagination);
          setSearchResults((arr) => [...arr, ...result]);
        } else {
          console.error(status);
          window.location.reload();
        }
      });
    });
  }

  const classes = useStyles();
  if (searchOptions === false) {
    return (
      <SearchOptions
        setOptions={(value) => {
          setSearchOptions(value);
        }}
      />
    );
  } else {
    return (
      <div className={classes.container}>
        <div ref={resultsContainer} />
        {isNewUser ? (
          <IntroCard
            completed={() => {
              localStorage.setItem("newUser", "false");
              setNewUser(false);
            }}
          />
        ) : !loading ? (
          searchResults.length === 0 ? (
            nextPage.hasNextPage ? (
              <h1 className={classes.underText}>Loading...</h1>
            ) : (
              <>
                <h1 className={classes.underText}>We're out of places!</h1>
                <Button
                  style={{ opacity: 0.5, width: "90%" }}
                  onClick={() => window.location.reload()}
                >
                  Reload and try again!
                </Button>
              </>
            )
          ) : (
            searchResults
              .slice(0, 2)
              .reverse()
              .map((value, index) => {
                return (
                  <Card
                    onSwipe={() => {
                      let newResults = searchResults;
                      newResults.shift();
                      setSearchResults([...newResults]);
                    }}
                    isFavorite={favorites.hasOwnProperty(value.place_id)}
                    toggleFavorite={(isFavorite) => {
                      if (isFavorite) {
                        let placeValues = {
                          name: value.name,
                          rating: value.rating,
                          latlong: {
                            lat: value.geometry.location.lat(),
                            long: value.geometry.location.lng(),
                          },
                          address: value.vicinity,
                        };
                        firestore
                          .collection("users")
                          .doc(auth.currentUser.uid)
                          .update({
                            [`favorites.${value.place_id}`]: placeValues,
                          });
                      } else {
                        firestore
                          .collection("users")
                          .doc(auth.currentUser.uid)
                          .update({
                            [`favorites.${value.place_id}`]: fieldValue.delete(),
                          });
                      }
                    }}
                    active={index === 1 || searchResults.length === 1}
                    key={value.place_id}
                    place={value}
                  />
                );
              })
          )
        ) : (
          <h1>Loading...</h1>
        )}
      </div>
    );
  }
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  underText: {
    opacity: 0.3,
  },
});

export default Places;
