import React from "react";
import MaterialIcon from "@material/react-material-icon";
import "@material/react-material-icon/dist/material-icon.css";
import { createUseStyles } from "react-jss";

function IconButton(props) {
  const classes = useStyles();
  return (
    <button {...props} className={classes.button}>
      <MaterialIcon
        style={{ fontSize: props.fontSize }}
        hasRipple={true}
        icon={props.icon}
      />
    </button>
  );
}

const useStyles = createUseStyles({
  button: {
    background: "none",
    border: "none",
    color: "#FFF",
  },
});

export default IconButton;
