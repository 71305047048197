import React from "react";
import { createUseStyles } from "react-jss";
import Button from "../components/elements/button";

function Onboarding(props) {
  const [loading, isLoading] = React.useState(false);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h1 className={classes.header}>FeedUs</h1>
        <p className={classes.subHeader}>A site to help the indecisive eat.</p>
      </div>
      <div className={classes.buttonContainer}>
        <p>We use Location to find places around you.</p>
        <Button
          onClick={() => {
            isLoading(true);
            props.getLocation();
          }}
          style={{ backgroundColor: loading ? "#8F9344" : "#F8FF65" }}
        >
          {loading ? "Getting Location..." : "Allow Location"}
        </Button>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerContainer: {
    margin: [20, 0],
  },
  header: {
    fontFamily: "'Barlow Semi Condensed', sans-serif",
    fontSize: 80,
    fontWeight: 800,
    color: "#FFF",
    margin: 0,
  },
  subHeader: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 22,
    fontWeight: 400,
    margin: 0,
  },
  buttonContainer: {
    width: "100%",
    alignSelf: "center",
    justifySelf: "center",
    justifyContent: "center",
    "& p": {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "'Roboto', sans-serif",
      opacity: 0.6,
    },
  },
});

export default Onboarding;
